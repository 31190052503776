import request from '@/utils/requestV2'
const qs = require('qs')

// 获取ADX有效产品
export function getAdxAllProduct (data) {
  return request({
    url: '/ooh-product/v1/adxproduct/all',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取产品下的单品
export function getAdxSkuList (data) {
  return request({
    url: '/ooh-product/v1/adxproduct/getskulist',
    method: 'POST',
    data: qs.stringify(data)
  })
}
// 获取产品下的单品剩余库存集合
export function getAdxStockAuantitys (data) {
  return request({
    url: '/ooh-product/v1/adxproduct/stockquantitys',
    method: 'POST',
    data: qs.stringify(data)
  })
}
