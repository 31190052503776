import request from '@/utils/requestV2'
const qs = require('qs')

// 获取ADX有效产品
export function addAdxResources (data) {
  return request({
    url: '/ooh-order/v1/adxorder/addresources',
    method: 'POST',
    timeout: 200000,
    data: qs.stringify(data)
  })
}
// 已采购的城市及数量
export function getCityQuantity (data) {
  return request({
    url: '/ooh-order/v1/adxorder/getcityquantity',
    method: 'POST',
    data: qs.stringify(data)
  })
}
