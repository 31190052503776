<template>
    <div @keydown.enter="handleSearchStation">
        <Divider dashed ><span class="divider-text">媒体采购</span></Divider>
        <h4 class="p-b-5">发布档期</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div v-for="(schedule,index) in orderBean.orderScheduleList" :key="index" class="p-l-20 p-r-20 m-b-2"
                    :class="checkedschedules.includes((schedule.startDate+'_'+schedule.endDate))?'workplatform-radio-button-active':'workplatform-radio-button'"
                    @click="changeCheckedschedules(schedule.startDate,schedule.endDate)">{{getScheduleInfo(schedule.startDate,schedule.endDate)}}</div>
                <a class="m-l-10" @click="modalEditSchedule=true">编辑档期</a>
            </i-col>
        </Row>

        <h4 class="p-b-5">可用产品</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <div v-for="product in allAdxProduct" :key="product.id" class="p-l-10 p-1-10 m-b-2" @click="changeCheckedProduct(product.id)"
                    :class="currentProductId===product.id?'workplatform-radio-button-active':'workplatform-radio-button'">{{product.name}}</div>
            </i-col>
        </Row>

        <h4 class="p-b-5">特色站点</h4>
        <Row class="p-b-5">
            <i-col span="24">
                <i-input type="text" size="small" clearable v-model="GenerationCondition.keywords"
                    style="width:250px" placeholder="输入关键字：商家、机构、站点名称等" ></i-input>

                <i-button size="small" type="primary" class="m-l-5" :disabled="GenerationCondition.keywords.length===0" icon="ios-search" @click="handleSearchStation">搜索</i-button>
            </i-col>
        </Row>
        <h4 v-if="currentCheckCity.length>0" class="p-b-5">选中站点范围</h4>
        <Row v-if="currentCheckCity.length>0" class="p-b-5">
            <i-col span="24">
              <p class="remark p-b-5" v-for="(city,index) in currentCheckCity" :key="index">
                <span class="text-orange">*</span>{{city.cityName}}：
                <span v-for="checkStation in currentCheckStaions.filter(x=>x.cityCode===city.cityCode)" :key="checkStation.stationId"
                  class="m-r-10 remark">{{checkStation.stationName}}</span>
              </p>
            </i-col>
        </Row>

        <h4 class="p-b-5">采购方式</h4>
        <Row class="p-b-5">
          <i-col span="24">
            <RadioGroup v-model="purchaseType" size="small" type="button" button-style="solid">
                <Radio label="1">按量采购</Radio>
                <Radio label="2">预算倒推</Radio>
            </RadioGroup>
          </i-col>
        </Row>
        <Row>
            <i-col span="24">
                <InputNumber v-if="purchaseType==='1'" size="small" :min="0" style="width:200px" v-model="purchaseNum"></InputNumber>
                <InputNumber v-else size="small" :max="10000000" style="width:200px" v-model="purchaseNum"
                  :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>
            </i-col>
        </Row>

        <h4 class="p-b-5 p-t-10">城市分配概览</h4>
        <p class="remark"><span class="text-orange">*</span> 城市按配额比例自动分配，预览数量/金额可手动调整</p>
        <Row :gutter="8" class="p-t-5">
            <i-col class="p-b-5" span="12" v-for="(sku,index) in skus" :key="index">
                <p class="p-b-5">
                  <a :class="chooseCityCode===sku.cityCode?'choose-active':''" @click="handleChangeCity(sku)">{{sku.cityName}}：</a>
                  (余量 <strong class="text-orange">{{sku.surplus}}</strong>)
                </p>
                <InputNumber v-if="purchaseType==='1'" size="small" style="width:100px" :max="sku.surplus" :min="0" v-model="sku.purchaseQuantity"></InputNumber>

                <InputNumber v-else size="small" :max="sku.surplus" style="width:100px" v-model="sku.purchaseQuantity"
                  :formatter="value => `￥ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')"
                  :parser="value => value.replace(/\￥\s?|(,*)/g, '')"></InputNumber>

                <p class="remark" v-if="purchaseNum>0">不低于{{purchaseType==='1'? sku.minQuantity:formatMoney(sku.minQuantity)}}</p>
            </i-col>
        </Row>

        <Row :gutter="4" class="p-t-10 text-right">
            <i-col span="24">
              <a class="m-r-10" @click="handleReloadMap"><Icon type="md-refresh" />刷新地图点位余量</a>
              <!-- <Button class="btn-black m-r-10" size="small" @click="handleReloadMap">地图显示点位余量</Button> -->
              <Button type="success" size="small" :disabled="isSubmit" :loading="spinShow"  @click="handleGenerationResources">自动生成点位方案</Button>
            </i-col>
        </Row>

        <editSchedule :orderBean="orderBean" :isShow.sync="modalEditSchedule" @on-update-order="handelUpdateOrderSchedule"/>
        <listRecommend ref="listRecommend" @handleAddPlan="beginAddResources"/>
        <stationSearch ref="stationSearch" @on-change-stations="handleChangeStation"/>

        <Spin fix size="large" v-if="spinShow">
          <Icon type="ios-loading" size=18 class="spin-icon-load"></Icon>
          <div>加载中...</div>
      </Spin>
    </div>
</template>

<script>
import editSchedule from '@/components/order/common/EditSchedule'
import listRecommend from '../components/ListRecommend'
import stationSearch from '../components/StationSearch'

import { getAdxAllProduct, getAdxSkuList, getAdxStockAuantitys } from '@/api/order/adxproduct'
import { getStationResourceQuantity } from '@/api/product/productresource'
import { getCityQuantity } from '@/api/order/adxorder'
import { addOrderPlan } from '@/api/order/orderitem'

import { applyPlan } from '@/api/dw/recommend'
import { GetCurrentSchedule } from '@/utils/dateFormat'
import { toMoney } from '@/utils/wnumb_own'

export default {
  components: {
    editSchedule, listRecommend, stationSearch
  },
  data () {
    return {
      checkedschedules: [], // 选中的档期
      allAdxProduct: [],
      currentProductId: 0,
      GenerationCondition: { // 生成资源的条件
        keywords: '',
        distance: 500
      },
      productSkuList: [], // 可选城市清单
      skus: [], // 采购参数清单
      purchaseNum: 0,
      spinShow: false,
      modalEditSchedule: false,
      chooseCityCode: '',
      chooseCityAssetIds: [],

      currentCheckStaions: [], // 当前选中的全部站点
      currentCheckCity: [], // 当前选中的站点城市分组

      purchaseType: '1'
    }
  },
  methods: {
    initPageData () {
      getAdxAllProduct().then(res => {
        this.allAdxProduct = res
        if (res.length > 0) {
          this.currentProductId = res[0].id
          this.loadProductSkuList()
        }
      })

      this.checkedschedules = []
      if (this.orderBean.orderScheduleList) {
        this.orderBean.orderScheduleList.forEach(res => {
          this.checkedschedules.push((res.startDate + '_' + res.endDate))
        })
      }
    },
    getScheduleInfo (startDate, endDate) {
      return GetCurrentSchedule(startDate, endDate)
    },
    changeCheckedschedules (startDate, endDate) {
      const value = startDate + '_' + endDate
      if (this.checkedschedules.includes(value)) {
        const index = this.checkedschedules.indexOf(value)
        if (this.checkedschedules.length === 1) {
          this.$Notice.warning({ desc: '至少需要选择一个发布档期' })
        } else {
          this.checkedschedules.splice(index, 1)
        }
      } else {
        this.checkedschedules.push(value)
      }

      this.loadSkuStock()
    },
    changeCheckedProduct (productId) {
      this.currentProductId = productId
      this.loadProductSkuList()
    },
    loadProductSkuList () {
      const postData = {
        productId: this.currentProductId
      }

      getAdxSkuList(postData).then(res => {
        this.productSkuList = res
        // 默认全部选中线路
        res.forEach(element => {
          this.chooseCityAssetIds = this.chooseCityAssetIds.concat(element.assetIdList)
        })
        this.loadSkuStock(false)
      })
    },
    handleChangeCity (skuCity) {
      if (this.chooseCityCode === skuCity.cityCode) {
        this.chooseCityCode = ''
        // 默认全部选中线路
        this.skus.forEach(element => {
          this.chooseCityAssetIds = this.chooseCityAssetIds.concat(element.assetIdList)
        })
        this.$store.commit('set_adx_currentCityCode', null)
        setTimeout(() => {
          this.$store.commit('set_adx_update_China_map', new Date())
        }, 500)
      } else {
        this.chooseCityCode = skuCity.cityCode
        this.chooseCityAssetIds = skuCity.assetIdList
        this.$store.commit('set_adx_currentCityCode', this.chooseCityCode)
        this.loadMapResource()
      }
    },
    loadSkuStock (reload = true) {
      if (reload) {
        this.loadMapResource()
      }
      const postData = {
        orderId: this.orderBean.id,
        productId: this.currentProductId,
        schedules: JSON.stringify(this.schedules),
        conditions: JSON.stringify(this.searchCondition)
      }

      getAdxStockAuantitys(postData).then(res => {
        this.skus = []
        this.productSkuList.forEach(element => {
          this.skus.push({
            productSkuId: element.productskuId,
            assetIdList: element.assetIdList,
            cityCode: element.cityCode,
            cityName: element.cityName,
            minQuantity: 0, // 最低采购数量，动态计算
            ratio: element.ratio, // 最低采购比例
            purchaseQuantity: 0,
            surplus: res.find(x => x.productSkuId === element.productskuId).quantity
          })
        })

        this.loadChinaMapData()
      })
    },
    handleGenerationResources () {
      const postData = {
        orderId: this.orderBean.id,
        productId: this.currentProductId,
        productskus: this.productskuQuantitys,
        schedules: JSON.stringify(this.schedules),
        conditions: JSON.stringify(this.searchCondition),
        stationIds: JSON.stringify(this.currentCheckStaions.map(x => x.stationId))
      }

      this.$refs.listRecommend.initData(postData, parseInt(this.purchaseType))
    },
    beginAddResources (planId) {
      this.spinShow = true
      const postData = {
        orderId: this.orderBean.id,
        planId: planId,
        schedules: JSON.stringify(this.schedules),
        conditions: JSON.stringify(this.searchCondition)
      }

      addOrderPlan(postData).then(res => {
        this.$Notice.success({
          desc: '添加资源成功,请在页面右侧【采购清单】中查看购买详情！'
        })

        // 默认选中编辑状态
        const defaultProduct = {
          productId: this.currentProductId,
          startDate: this.schedules[0].startDate,
          endDate: this.schedules[0].endDate
        }
        this.$store.commit('set_adx_defaultProduct', defaultProduct)
        this.$store.commit('set_adx_update_purchase', new Date())

        // 标识推荐为应用, 用于统计调用度
        applyPlan({ planId: planId })

        // 刷新可用生成数量
        this.loadSkuStock(false)
        this.purchaseNum = 0
        this.spinShow = false
      }).catch(() => {
        this.spinShow = false
      })
    },
    handelUpdateOrderSchedule () { // 更新订单档期
      const query = { orderId: this.orderBean.id }
      this.$store.dispatch('getOrderBaseInfo', query)
    },
    loadMapResource () {
      // 查询当前订单已采购资源
      const searchResourceCondition = {
        excludeOrderId: this.orderBean.id,
        assetIds: JSON.stringify(this.chooseCityAssetIds), // 获取当前选中城市的线路清单
        productId: this.currentProductId,
        schedules: JSON.stringify(this.schedules)
      }
      getStationResourceQuantity(searchResourceCondition).then(res => {
        if (res.length === 0) { // 清空页面
          this.$store.commit('set_adx_stations', [])
          this.$store.commit('set_adx_update_map', new Date())
        } else {
          this.$store.commit('set_adx_show_assets', this.chooseCityAssetIds)
          this.$store.commit('set_adx_map_type', 'full')
          this.$store.commit('set_adx_check_schedule', this.schedules)
          this.$store.commit('set_adx_stations', res)
          this.$store.commit('set_adx_search_condition', this.searchCondition)

          this.$store.commit('set_adx_update_map', new Date())
        }
      })
    },
    loadChinaMapData () {
      const postData = {
        orderId: this.orderBean.id,
        productId: this.currentProductId,
        schedules: JSON.stringify(this.schedules)
      }

      getCityQuantity(postData).then(res => {
        const updateChinaMapData = {
          purchaseCityData: [], // 已采购的城市
          allowCityData: []// 允许采购的城市
        }
        this.skus.forEach(element => {
          if (res.find(x => x.cityCode === element.cityCode)) { // 已采购
            updateChinaMapData.purchaseCityData.push({
              name: element.cityName.replace('市', ''),
              cityCode: element.cityCode,
              value: 100,
              surplus: element.surplus
            })
          } else {
            updateChinaMapData.allowCityData.push({
              name: element.cityName.replace('市', ''),
              cityCode: element.cityCode,
              value: 100,
              surplus: element.surplus
            })
          }
        })
        // 临时加入无锡数据
        updateChinaMapData.allowCityData.push({
          name: '无锡',
          cityCode: 320200,
          value: 100,
          surplus: 0
        })

        // 开始更新全国地图
        // updateChinaMapData.purchaseCityData = [
        //   { name: '南京', cityCode: 320100, value: 100, surplus: 3580 },
        //   { name: '无锡', cityCode: 320200, value: 100, surplus: 658 },
        //   { name: '杭州', cityCode: 330100, value: 100, surplus: 4456 },
        //   { name: '宁波', cityCode: 330200, value: 100, surplus: 125 },
        //   { name: '金华', cityCode: 330700, value: 100, surplus: 1358 }
        // ]

        this.$store.commit('set_adx_chinaMap_data', updateChinaMapData)
        this.$store.commit('set_adx_update_China_map', new Date())
      })
    },
    handleReloadMap () {
      this.chooseCityCode = ''
      this.$store.commit('set_adx_currentCityCode', null)
      setTimeout(() => {
        this.loadChinaMapData()
      }, 500)
    },
    handleSearchStation () {
      if (this.GenerationCondition.keywords.length > 0) {
        this.$refs.stationSearch.initData(this.GenerationCondition.keywords, this.currentProductId)
      }
    },
    handleChangeStation (checkStations) {
      this.currentCheckStaions = checkStations
      this.currentCheckCity = []

      checkStations.forEach(element => {
        if (!this.currentCheckCity.find(x => x.cityCode === element.cityCode)) {
          this.currentCheckCity.push({
            cityCode: element.cityCode,
            cityName: element.cityName
          })
        }
      })
    },
    formatMoney (number) {
      return toMoney(number)
    }
  },
  computed: {
    orderBean () {
      return this.$store.state.adx.orderBean
    },
    // 获取选择的订单档期
    schedules: function () {
      const schedules = []
      this.checkedschedules.forEach(item => {
        const shcedule = item.split('_')
        schedules.push({ startDate: shcedule[0], endDate: shcedule[1] })
      })
      return schedules
    },
    searchCondition () {
      return {
        distance: this.GenerationCondition.distance,
        keywords: this.GenerationCondition.keywords
      }
    },
    productskuQuantitys () {
      const result = []
      this.skus.forEach(element => {
        result.push({
          productskuId: element.productSkuId,
          quantity: element.purchaseQuantity
        })
      })

      return JSON.stringify(result)
    },
    isSubmit: function () {
      let total = 0
      let flag = false
      this.skus.forEach(element => {
        if (element.purchaseQuantity < element.minQuantity) {
          flag = true
        }
        total += element.purchaseQuantity
      })

      return total === 0 || flag
    }
  },
  watch: {
    orderBean (val) {
      this.initPageData()
    },
    'GenerationCondition.keywords' (val) {
      // this.loadSkuStock()
    },
    purchaseNum () {
      this.skus.forEach(element => {
        element.minQuantity = (this.purchaseNum > 0) ? parseInt(this.purchaseNum * element.ratio) : 0
        if (element.minQuantity > element.purchaseQuantity) {
          element.purchaseQuantity = element.minQuantity
        }
      })
    },
    purchaseType () {
      this.purchaseNum = 0
      this.skus.forEach(element => {
        element.minQuantity = 0
        element.purchaseQuantity = 0
      })
    }
  }
}
</script>
